@font-face {
  font-family: 'information';
  src:  url('../fonts/information.eot?uw8pen');
  src:  url('../fonts/information.eot?uw8pen#iefix') format('embedded-opentype'),
    url('../fonts/information.ttf?uw8pen') format('truetype'),
    url('../fonts/information.woff?uw8pen') format('woff'),
    url('../fonts/information.svg?uw8pen#information') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.m-control.m-container.m-information-container [class^="g-"]::before, .m-control.m-container.m-information-container [class*=" g-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'information' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.m-information-container .information-info:before {
  content: "\e90a";
}
