.m-panel.m-plugin-information {
    z-index: 99999;
}

.m-control.m-container.m-information-container button {
    color: white;
    background-color: #71a7d3 !important;
    opacity: 1;
    border-radius: 40px;
    border: 2px solid white;
}

.m-areas>div.m-area>div.m-panel>div.m-panel-controls>.m-control.m-container.m-information-container.activated>button {
    background-color: #D1E6F8 !important;
    color: #364B5F !important;
}

.m-information-container {
    top: 20px;
    left: 300px;
}

.m-popup table.mapea-table td.header {
    text-align: center;
    padding-top: 10px;
    border-bottom: 1px ridge #6C6C6C
}

.m-information-content-info {
    min-width: 250px;
    margin-bottom: .7rem;
}

.m-information-content-info-body.m-content-collapsed,
span.m-content-collapsed {
    display: none;
}

.m-information-content-info .m-information-content-info-header {
    display: flex;
    align-items: center;
    font-size: 12px;
    background-color: #eee;
    margin-bottom: 5px;
    color: black;
    padding: 5px;
}

.m-information-content-info .m-information-content-info-header .m-arrow-right {
    border: 7px solid transparent;
    border-left-color: black;
    cursor: pointer;
}

.m-information-content-info .m-information-content-info-header .m-arrow-down {
    border: 7px solid transparent;
    border-top-color: black;
    cursor: pointer;
    margin-right: 5px;
}

.m-information-content-info .m-information-content-info-header div:nth-child(2):hover {
    text-decoration: underline;
    cursor: pointer;
}

/*SOLUCIÓN BUGS MAQUETACIÓN*/
.m-popup .m-information-content-info-body #Borde2 {
    position: unset !important;
    left: unset !important;
    top: unset !important;
    min-width: 950px !important;
}

.m-popup .m-information-content-info-body #Borde2 .bolaGeo {
    display: none !important;
}

.m-popup>div.m-content>div.m-body {
    /*max-width: 300px !important;*/
    overflow-x: auto !important;
    resize: horizontal;
    min-width: 280px !important;
}